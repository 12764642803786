export const APP_CONFIG = {
  sitename: 'Curewell Medical Center',
  addresses: [
    {
      id: 1,
      name: 'Lombard',
      branchId: '1604502179475',
      addressLine1: '2340 S Highland Ave Suite',
      addressLine2: '260 Lombard IL 60148',
      location: {
        lat: 41.8429828,
        lon: -88.0143908
      },
      contact: {
        call: {
          no: '+16308892000',
          text: '+1-630-889-2000',
          trail: '(Lombard)'
        },
        fax: {
          no: '',
          text: '630-889-2299'
        }
      }
    },
    {
      id: 2,
      name: 'Willowbrook',
      branchId: '1604502076972',
      addressLine1: '621 Plainfield Rd Suite 107',
      addressLine2: 'Willowbrook IL 60527',
      location: {
        lat: 41.762401,
        lon: -87.9410688
      },
      contact: {
        call: {
          no: '+16303238595',
          text: '+1-630-323-8595',
          trail: '(Willowbrook)'
        },
        fax: {
          no: '',
          text: '630-889-2299'
        }
      }
    },
    // {
    //   id: 2,
    //   name: 'Burr Ridge',
    //   branchId: '1610109855169',
    //   addressLine1: '6120 South Elm St',
    //   addressLine2: 'Burr Ridge, IL 60527',
    //   location: {
    //     lat: 41.7786578,
    //     lon: -87.9227169
    //   },
    //   contact: {
    //     call: {
    //       no: '+16308892299',
    //       text: '630-889-2299'
    //     },
    //     fax: {
    //       no: '',
    //       text: ''
    //     }
    //   }
    // },
    // {
    //   id: 3,
    //   name: 'Berwyn',
    //   branchId: '34216535',
    //   addressLine1: '6925 Cermak Road',
    //   addressLine2: 'Berwyn IL 60402',
    //   location: {
    //     lat: 41.8503152,
    //     lon: -87.799079
    //   },
    //   contact: {
    //     call: {
    //       no: '+16303238595',
    //       text: '630-323-8595'
    //     },
    //     fax: {
    //       no: '',
    //       text: '630-735-5138'
    //     }
    //   }
    // },
  ],
  email: 'curewellmedicalcenter@gmail.com',
  mapLink: '',
  copyright: {
    year: new Date().getFullYear(),
    owner: 'Curewell Medical Center'
  },
  // appoLink: 'https://www.solvhealth.com/curewell-medical-center-berwyn-il-ABbrdA',
  appoLink: 'https://patientmd.com/#/doctor/Rathna%20Yallapragada_1604465616541',
  profileLink: 'https://patientmd.com/#/doctor/Rathna%20Yallapragada_1604465616541',
  bizLink: 'https://app.patientmd.com/#/business/Curewell%20Medical%20Center?clinic=1'
}