import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule) },
  { path: 'about', loadChildren: () => import('./views/about/about.module').then(m => m.AboutModule) },
  { path: 'services', loadChildren: () => import('./views/services/services.module').then(m => m.ServicesModule) },
  { path: 'testimonials', loadChildren: () => import('./views/testimonials/testimonials.module').then(m => m.TestimonialsModule) },
  { path: 'contact', loadChildren: () => import('./views/contact/contact.module').then(m => m.ContactModule) },
  { path: 'privacy-policy', loadChildren: () => import('./views/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
