import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PipesModule } from '../pipes/pipes.module';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ToastComponent } from './toast/toast.component';
import { EducationalVideosComponent } from './educational-videos/educational-videos.component';
import { VideoPreviewComponent } from './educational-videos/video-preview.component';
import { AllServicesComponent } from './all-services/all-services.component';
import { BlogsComponent } from './blogs/blogs.component';
import { BlogCoverComponent } from './blogs/cover.component';
import { DealCoverComponent } from './deal-services/deal-cover.component';
import { DealServicesComponent } from './deal-services/deal-services.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ToastComponent,
    AllServicesComponent,
    EducationalVideosComponent,
    VideoPreviewComponent,
    BlogsComponent,
    BlogCoverComponent,
    DealCoverComponent,
    DealServicesComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipesModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ToastComponent,
    AllServicesComponent,
    EducationalVideosComponent,
    VideoPreviewComponent,
    BlogsComponent,
    BlogCoverComponent,
    DealCoverComponent,
    DealServicesComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class ComponentsModule { }
