<header id="header" class="site-header">
  <div class="overlay" *ngIf="menuShown" (click)="menuShown = false"></div>
  <div class="top-header">
    <div class="top-header__container">
      <div class="logo">
        <a routerLink="/" class="logo-link" [class.nolink]="isHomePage">
          <img src="assets/images/curewell-logo.png" alt="Curewell Logo" />
        </a>
      </div>
      <div class="top-header__actions">
        <div class="contact">
          <div class="call">
            <span class="icon"></span>
            <span> <em>For Booking, Call Us: </em> <a href="tel:{{contact.call.no}}">{{contact.call.text}}
                {{contact.call.trail}}</a>, <a href="tel:{{contact2.call.no}}">{{contact2.call.text}}
                {{contact2.call.trail}}</a> </span>
          </div>
        </div>
      </div>
      <button class="hamburger" (click)="menuShown = !menuShown" title="Menu Button"
        aria-label="Toggle Navigation"></button>
    </div>
  </div>
  <nav class="menu-header" [class.shown]="menuShown">
    <div class="inner-menu-wrapper">
      <ul>
        <li>
          <a [routerLink]="['/']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">Home</a>
        </li>
        <li>
          <a [routerLink]="['/about']" routerLinkActive="active">About</a>
        </li>
        <li>
          <a [routerLink]="['/services']" routerLinkActive="active">Services</a>
        </li>
        <li>
          <a [routerLink]="['/testimonials']" routerLinkActive="active">Testimonials</a>
        </li>
        <li>
          <a [routerLink]="['/contact']" routerLinkActive="active">Contact Us</a>
        </li>
        <li>
          <a (click)="bookAppointment()" class="btn-link green">Book Appointment</a>
        </li>
      </ul>
      <div class="social-links no-help">
        <ul>
          <li>
            <a href="https://www.facebook.com/curewellmedicalcenter" target="_blank" rel="noreferrer"><img
                src="assets/images/facebook-logo.png" alt="facebook-logo" /></a>
          </li>
          <li>
            <a href="https://twitter.com/CurewellC" target="_blank" rel="noreferrer"><img
                src="assets/images/twitter-logo.png" alt="twitter-logo" /></a>
          </li>
          <li>
            <a href="https://www.instagram.com/curewellmedicalcenter/" target="_blank" rel="noreferrer"><img
                src="assets/images/instagram-logo.png" alt="instagram-logo" /></a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/curewell-health-center/" target="_blank" rel="noreferrer"><img
                src="assets/images/linkedin-logo.png" alt="linkedin-logo" /></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
<!-- <button class="go-up" *ngIf="upAnchorShown" (click)="scrollToElement('top')">Back to Top</button>
<button class="go-up-mobile" *ngIf="upAnchorShown" (click)="scrollToElement('top')">Back to Top</button> -->

<div class="fab-area">
  <button class="radiate" id="btn" (click)="scrollToElement('subscription')">
    <img src="assets/images/newsletter-envelope.png" alt="" />
    <span>Subscribe to newsletter</span>
  </button>
</div>