import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SafeResourceUrl, DomSanitizer, } from '@angular/platform-browser';
import { ToastService } from './toast.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  getSlots = false;
  errorOccured = false;

  private defLocation = new BehaviorSubject('');
  currentLocation = this.defLocation.asObservable();

  constructor(
    private sanitizer: DomSanitizer,
    private toast: ToastService,
    // @Inject(DOCUMENT) private document: Document
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const location = localStorage.getItem('cw-def-location') || 'Lombard';
      this.defLocation.next(location);
    }
  }

  changeLocation(loc: string) {
    this.defLocation.next(loc);
  }

  getBlogContentAsText(blogContent: string) {
    if (isPlatformBrowser(this.platformId)) {
      return this.extractContent(decodeURIComponent(blogContent))
    }
    return decodeURIComponent(blogContent);
  }

  extractContent(html) {
    return (new DOMParser).parseFromString(html, "text/html").documentElement.textContent;
  }


  createCymd(d = new Date()) {
    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();

    return { year, month, day }
  }

  createDateTimeStamp(d, t) {

    let { day, month, year } = d;
    month = month - 1;
    const hr = t.starthr;
    const mins = t.startmin;
    return new Date(year, month, day, hr, mins).getTime()

  }

  slotArrSort(arr: any[]) {
    arr.sort((a, b) => {
      const aTime = this.createParseTime(a);
      const bTime = this.createParseTime(b);

      if (aTime > bTime) {
        return 1;
      } else {
        return -1;
      }
    })

    return arr;
  }

  createParseTime(args) {
    const hours = args.starthr;
    const mins = args.startmin;
    const date = new Date();
    const dateString = date.toDateString();
    const parser = Date.parse(`${dateString} ${hours}:${mins}`);
    return parser;
  }


  sortWithTimestamp(arr) {
    arr.sort((a, b) => {
      if (a.dtCreated < b.dtCreated) {
        return 1;
      } else if (a.dtCreated == b.dtCreated) {
        return 0;
      } else {
        return -1;
      }
    });
    return arr;
  }

  flattenDeep(arr: any[]) {
    return arr.reduce(
      (acc, val) =>
        Array.isArray(val)
          ? acc.concat(this.flattenDeep(val))
          : acc.concat(val),
      []
    );
  }

  showRatings(rating: number) {
    const ratings = [];
    for (let i = 1; i <= 5; i++) {
      // console.log(i);
      if (i <= rating) {
        ratings.push("star");
      } else {
        ratings.push("star_border");
      }
    }
    return ratings;
  }

  getDistance(lat1, lon1, lat2, lon2, unit = 'N') {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1 / 180;
      var radlat2 = Math.PI * lat2 / 180;
      var theta = lon1 - lon2;
      var radtheta = Math.PI * theta / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") { dist = dist * 1.609344 }
      if (unit == "N") { dist = dist * 0.8684 }
      return dist;
    }
  }

  getYouTubeThumb(url: string, thumb = true, autoplay = 1, controls = 0): SafeResourceUrl {
    const videoObj = this.parseVideo(url);
    let src: string;
    src = thumb ? `https://img.youtube.com/vi/${videoObj.id}/hqdefault.jpg` :
      `https://www.youtube.com/embed/${videoObj.id}?autoplay=${autoplay}&controls=${controls}&rel=0&modestbranding=1`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(src);
  }

  parseVideo(url: string) {
    let urlType: string;
    url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);
    if (RegExp.$3.indexOf('youtu') > -1) {
      urlType = 'youtube';
    } else if (RegExp.$3.indexOf('vimeo') > -1) {
      urlType = 'vimeo';
    }
    return {
      type: urlType,
      id: RegExp.$6
    };
  }

  copyLinkToClipboard(str: string) {

    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(str);
      return;
    }

    navigator.clipboard
      .writeText(str)
      .then(() => {
        this.toast.success('Link copied to your clipboard');
      })
      .catch(err => {
        console.error('Copy Error:', err);
        this.toast.error('Oops, unable to copy');
      });
  }

  fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.setAttribute('readonly', '');
    textArea.style.position = 'absolute';
    textArea.style.left = '-9999px';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      if (successful) {
        this.toast.success('Link copied to your clipboard');
      }
    } catch (err) {
      console.error('Fallback Error:', err);
      this.toast.error('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
  }

  formatCurrency(price: number, currency: any, format = 'UV'): string {
    // const format = 'VC'; // 'Code Value'
    const { currcode, currname, } = currency;
    return format === 'VU' ? `${price} ${currcode}` : `${currname} ${price}`;
  }

}
