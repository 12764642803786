import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UtilityService } from '../../services/utility.service';
import { InteractionService } from '../../services/interaction.service';
import { APP_CONFIG } from '../../app.global';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  siteData = APP_CONFIG;
  locations: any[] = [];
  currentLocation = '';
  isHomePage: boolean;
  upAnchorShown: boolean;
  menuShown = false;

  contact: any = null;
  contact2: any = null;
  url = '';

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    this.upAnchorShown = window.pageYOffset > 50 ? true : false;
  }

  constructor(
    private router: Router,
    private utility: UtilityService,
    private interact: InteractionService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

    this.locations = this.siteData.addresses;
    this.contact = this.locations[0].contact;
    this.contact2 = this.locations[1].contact;
    if (isPlatformBrowser(this.platformId)) {
      const loc = localStorage.getItem('cw-def-location') || 'Berwyn';
      this.currentLocation = loc;
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart || event instanceof NavigationEnd) {
        this.isHomePage = event.url === '/' ? true : false;
      }
      if (event instanceof NavigationEnd) {
        this.menuShown = false
      }
    });
  }

  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart || event instanceof NavigationEnd) {
        this.isHomePage = event.url === '/' ? true : false;
        this.url = event.url;
      }

      // if (event instanceof NavigationEnd) {}
    });
  }

  switchLocation(e) {
    const location = e.target.value;
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('cw-def-location', location);
      this.utility.changeLocation(location);
      this.currentLocation = location;
    }
  }

  scrollToElement(id: string) {
    console.log(this.url);

    if (this.url !== '/') {
      this.router.navigate(['/'])
        .then(() => {
          // console.log(el);
          const goto = this.url === '/services' ? '#videos' : '#homeBlogs';
          setTimeout(() => {
            const el: HTMLElement = document.querySelector(goto);
            el.scrollIntoView({ behavior: 'smooth', block: this.url !== '/services' ? 'start' : 'end' });
          }, 500);
        });
    } else {
      setTimeout(() => {
        const el: HTMLElement = document.querySelector('#' + id);
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 200);
    }
  }

  // bookAppointment(external = true) {
  //   this.interact.toggleLoading(true);
  //   if (isPlatformBrowser(this.platformId)) {
  //     window.location.href = external ? this.siteData.appoLink : this.siteData.profileLink;
  //   }
  // }

  bookAppointment() {
    this.interact.toggleLoading(true);
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = this.siteData.bizLink;
    }
  }

}
