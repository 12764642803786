export const environment = {
  production: true,
  ENV_NAME: 'production',
  API_URL: 'https://patientmdtalk.com:443/',
  GO_URL: 'https://patientmd.com/#/',
  SITE_URL: 'https://curewellhealth.com/',
  PROFILE_URL: 'https://patientmd.com/#/doctor/Rathna%20Yallapragada_1604465616541',
  CW: {
    id: 'yallapragada.rathna_gmail.com_biz',
    branchId: '1517310610000',
    docId: 'yallapragada.rathna@gmail.com',
    docName: 'Rathna Yallapragada,MD'
  },
  GMAP_Key: 'AIzaSyCjbongHh5OwOmgILPb0UP4KWOO_DcDGpg',
};
