<footer class="cw">
  <div class="container">
    <div class="footer-wrapper">
      <div class="logo-area">
        <a [routerLink]="['/']"><img src="assets/images/curewell-logo.png" alt="curewell-logo"></a>
      </div>
      <div class="links">
        <a [routerLink]="['/']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">Home</a>
        <a [routerLink]="['/about']" routerLinkActive="active">About</a>
        <a [routerLink]="['/services']" routerLinkActive="active">Services</a>
        <a [routerLink]="['/testimonials']" routerLinkActive="active">Testimonials</a>
        <a [routerLink]="['/privacy-policy']" routerLinkActive="active">Privacy Policy</a>
        <a [routerLink]="['/contact']" routerLinkActive="active">Contact Us</a>
      </div>
      <div class="copyright">
        <span>
          &copy; {{copright.year}} {{copright.owner}}
        </span>
        <span>
          All rights reserved
        </span>
      </div>
    </div>
    <div class="power">
      <span>
        Powered by <a href="https://patientmd.com"> <span class="blu">Patient</span>MD</a>
      </span>
    </div>
  </div>
</footer>